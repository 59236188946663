import React from "react"
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

import Nav from '../components/nav'
import Base from '../layouts/base'

export default ({ data }) => (
  <Base className="min-h-screen flex flex-col">
    <Helmet>
      <html lang="en" />

      <title>Nathan Dunn - Software Developer, Consultant and Speaker</title>
      <meta name="description" content="Nathan Dunn is a software developer, consultant and speaker based in Gateshead, UK." />
    </Helmet>

    <Nav />

    <div className="bg-olive flex flex-1 py-5">
      <div className="flex flex-col items-center m-auto text-white text-center w-full lg:w-6/12 px-4 font-heading">
        <Image 
          fixed={data.picture.childImageSharp.fixed}
          alt="Nathan Dunn"
          className="rounded-full my-6 border-solid border-4 border-white"
          draggable={false}
        />

        <div className="leading-snug">
          <h1 className="text-6xl font-black mb-3 leading-tight">
            Nathan Dunn
          </h1>
          <h2 className="text-3xl font-extrabold mb-8">
            Software developer, consultant and speaker
          </h2>
        </div>

        <div className="my-3">
          <p className="mb-3">Hi <span role="img" aria-label="waving hand">👋</span> I'm Nathan, a software developer and consultant based in Gateshead, UK.</p>

          <p className="mb-3">
            I run <a href="http://solosoft.co.uk" rel="external noopener" className="font-semibold hover:underline">Solosoft</a>, a boutique software development
            agency specialising in PHP and Laravel development and building Software-as-a-Service products.
          </p>

          <p className="mb-4">
            I co-organise <a href="https://phpne.org.uk" rel="external noopener" className="font-semibold hover:underline">PHP North East</a>,
            a monthly event for PHP developers in the North East.
          </p>
        </div>

        <div className="text-lg font-semibold mt-4">
          <a className="mr-3" href="https://x.com/nthndnn" rel="noreferrer noopener">X</a>
          <a className="mr-3" href="https://t.me/nathandunn" rel="noreferrer noopener">Telegram</a>
          <a className="mr-3" href="http://github.com/nthndnn" rel="noreferrer noopener">GitHub</a>
          <a className="mr-3" href="https://www.linkedin.com/in/nathanjdunn/" rel="noreferrer noopener">LinkedIn</a>
        </div>
      </div>
    </div>
  </Base>
)

export const query = graphql`
  query {
    picture: file(relativePath: { regex: "/nathan-dunn-headshot/" }) {
      childImageSharp {
        fixed(width: 175, height: 175, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`